import AuthContainer from 'common/components/Layout/AuthLayout/AuthContainer'
import { GetServerSidePropsContext } from 'next'
import Head from 'next/head'
import React from 'react'
import { auth } from 'services/auth/auth.singleton'
import { LoginComponent } from 'services/auth/login/LoginComponent'
import { AuthLayout } from '../common/components/Layout/AuthLayout/AuthLayout'

const Login = () => {
  return (
    <AuthContainer>
      <Head>
        <title>Login</title>
      </Head>
      <AuthLayout>
        <LoginComponent />
      </AuthLayout>
    </AuthContainer>
  )
}

export const getServerSideProps = async (context: GetServerSidePropsContext) => {
  const { req, res } = context
  try {
    return {
      props: {},
    }
  } catch (error: any) {
    const status = error?.response?.status || 500
    if (status === 401) {
      console.log('cleared session cookie from login')

      auth.clearSessionCookie(res)
      return {
        props: {
          signedOut: true,
        },
      }
    }

    console.log('error', error)
    return { props: {} }
  }
}

Login.getLayout = (page: React.ReactNode | React.ReactNode[]) => page

export default Login
