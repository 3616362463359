import { Button } from 'common/components/Button/Button'
import { pagesPaths } from 'common/router/routes.utils'
import { Validations } from 'common/utils/validation/regexes'
import { useAtom } from 'jotai'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { ChangeEvent, useState } from 'react'
import { toast } from 'react-toastify'
import { userAccountStatusEnum, userCategoryEnum } from 'services/auth/auth.enums'
import { ApiPathsUsers, login } from 'services/auth/auth.service'
import { CompanySignUpDialog } from 'services/companies/components/verification/CompanySignUpDialog'
import styled from 'styled-components'
import { useSWRConfig } from 'swr'
import useSWRMutation from 'swr/mutation'
import { Input } from '../../../common/components/Forms/Inputs/Input'
import { auth } from '../auth.singleton'
import { showCompanySignUpDialogAtom } from '../auth.state'

export const LoginComponent = () => {
  const initialLoginState = {
    email: '',
    password: '',
  }
  const router = useRouter()
  const { redirect: redirectQuery } = router.query
  const { mutate } = useSWRConfig()

  // global state
  const [companySignUpDialog, setCompanySignUpDialog] = useAtom(showCompanySignUpDialogAtom)

  // local state
  const [data, setData] = useState(initialLoginState)
  const [error, setError] = useState(false)

  // api
  const { trigger: triggerLogin, isMutating } = useSWRMutation(ApiPathsUsers.login, login, {
    onError() {
      if (!error) {
        toast.error('Invalid Email or Password')
      }

      setError(true)
    },
    onSuccess: async (user) => {
      try {
        await mutate(
          () => true, // which cache keys are updated
          undefined, // update cache data to `undefined`
          { revalidate: false }, // do not revalidate
        )
        // await httpServer.get(ApiPathsCompany.myDeleteAccountRequest, {
        //   headers: { Authorization: `Bearer ${user.tokens.accessToken}` },
        // })

        // toast.error(
        //   'Account deletion in progress. For security reasons, you cannot log in until the deletion is complete. If you have any concerns, please contact our support team.',
        // )
        await auth.setSession(user)

        if (redirectQuery && typeof redirectQuery === 'string') {
          router.replace(decodeURIComponent(redirectQuery))
        }

        if (user.status === userAccountStatusEnum.pendingVerification) {
          return router.push(`${pagesPaths.verifyAccount}?email=${user.email}`)
        }

        if ([userCategoryEnum.VALIDATOR, userCategoryEnum.ADMIN].includes(user.category)) {
          return router.push(pagesPaths.analytics)
        }

        if ([userAccountStatusEnum.active].includes(user.status)) {
          return router.push(pagesPaths.dashboard)
        }
      } catch (error: any) {
        console.log('error', error)
        if (error.response.status === 404) {
          if ([userCategoryEnum.CANDIDATE].includes(user.category)) {
            toast.error('Apologies, no account linked to this email found.')
          }
        }
      }
    },
  })

  const handleValidation = () => {
    if (!Validations.isEmail(data.email)) setError(true)
    if (!Validations.minLenght(data.password)) return setError(true)
    if (!Validations.oneDigit(data.password)) return setError(true)
    if (!Validations.oneUppercase(data.password)) return setError(true)
    if (!Validations.oneLowercase(data.password)) return setError(true)
    if (!Validations.oneSpecialCharacter(data.password)) return setError(true)

    return true
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setError(false)
    setData((prevState) => ({ ...prevState, [name]: value }))
  }
  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (!handleValidation()) return !error && toast.error('Invalid Email or Password')
    if (!error) {
      setError(false)
      await triggerLogin({ ...data, email: data.email.toLocaleLowerCase() })
    }
  }

  return (
    <>
      <CompanySignUpDialog
        isVisible={companySignUpDialog.visible}
        firstName={companySignUpDialog.firstName}
        setIsVisible={(visible) => setCompanySignUpDialog((c) => ({ ...c, visible }))}
      />
      <form className="px-4" onSubmit={handleSubmit}>
        <TextContainer>
          <p>Hello There 🖐</p>
          <h3>Welcome back!</h3>
        </TextContainer>
        <div className="flex flex-column gap-3">
          <Input
            name="email"
            label="Email"
            placeholder="Type here"
            value={data.email}
            error={error}
            onChange={handleChange}
            data-cy="login-input-email"
          />
          <Input
            name="password"
            label="Password"
            type="password"
            placeholder="Type here"
            error={error}
            onChange={handleChange}
            data-cy="login-input-password"
          />
          <ForgotLink href="forgot-password" data-test="forgotPasswordButton">
            Forgot Password ?
          </ForgotLink>
        </div>
        <Button
          type="submit"
          className="w-full my-5 border-round-lg"
          size="large"
          isLoading={isMutating}
          data-cy="login-button-sign-in"
        >
          Sign In
        </Button>

        <TextContainer>
          <p className="footerText">
            Don&apos;t have an account? Please{' '}
            <Link className="secondary_100" href="/sign-up" data-test="signUpButton">
              Sign Up
            </Link>
          </p>
        </TextContainer>
      </form>
    </>
  )
}

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    font-weight: 600;
    font-size: 22px;
    line-height: 140%;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;
  }
  .footerText {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;
  }
  margin-bottom: 2rem;
`

const ForgotLink = styled(Link)`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
`
