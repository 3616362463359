import { Dialog } from 'primereact/dialog'

import { Button } from 'common/components/Button/Button'
import styled from 'styled-components'
import SeaplifyLogo from '../../../../assets/loginLogo.svg'

interface ICompanyWelcomeDialog {
  isVisible: boolean
  setIsVisible: (isVisible: boolean) => void
  firstName?: string
}
export const CompanySignUpDialog = ({ isVisible, setIsVisible, firstName = '' }: ICompanyWelcomeDialog) => {
  return (
    <StyledDialog
      headerClassName="header-dialog"
      header={<SeaplifyLogo className="logo" width="294.37" height="22.36" />}
      visible={isVisible}
      onHide={() => setIsVisible(false)}
      draggable={false}
    >
      <Div>
        <h1>Welcome to Seaplify {firstName}</h1>
        <p>You must login and validate your email before you can use the Seaplify platform.</p>

        <Button onClick={() => setIsVisible(false)} size="large" data-cy="login-button-login">
          Login
        </Button>
      </Div>
    </StyledDialog>
  )
}

const Div = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 19px;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;
    margin-bottom: 32px;
  }
  .p-component-overlay {
    backdrop-filter: blur(10px);
  }
`

const StyledDialog = styled(Dialog)`
  width: 720px;
`
